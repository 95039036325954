<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model='filters.name' placeholder="输入人群名称" clearable @clear='handleClear'></el-input>
                    </el-form-item>
                    <el-form-item label="类型:">
                        <el-select v-model="filters.groupType" clearable placeholder="请选择证书数量">
                            <el-option
                                v-for="item in groupTypeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            :cell-style="rowClass"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
            <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
            <el-table-column prop="Name" label="人群名称" align="center"></el-table-column>
            <!-- <el-table-column prop="PerSonDefini" label="人群定义" align="center"></el-table-column> -->
            <el-table-column prop="GroupTypeName" label="类型" align="center"></el-table-column>
            <el-table-column prop="UnitQuantity" label="详细名单" align="center">
                <template slot-scope='scope'>
                    <router-link v-if="scope.row.GroupType == 1" :to="{path:'/CrowdList',query:{hasPhone: null,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantity}}</router-link>
                    <router-link v-if="scope.row.GroupType == 2" :to="{path:'/YingxunList',query:{hasPhone: null,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantity}}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="UnitQuantityPhone" label="有手机号数量" align="center">
                <template slot-scope='scope'>
                    <!-- <router-link :to="{path:'/CrowdList',query:{hasPhone: 0,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantityPhone}}</router-link> -->
                    <router-link v-if="scope.row.GroupType == 1" :to="{path:'/CrowdList',query:{hasPhone: 0,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantityPhone}}</router-link>
                    <router-link v-if="scope.row.GroupType == 2" :to="{path:'/YingxunList',query:{hasPhone: 0,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantityPhone}}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="UnitQuantityNoPhone" label="无手机号数量" align="center">
                <template slot-scope='scope'>
                    <!-- <router-link :to="{path:'/CrowdList',query:{hasPhone: 1,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantityNoPhone}}</router-link> -->
                    <!-- <router-link :to="{path:'/SendNoteList',query:{hasPhone:0}}" style='color:#409EFF;'>{{scope.row.UnitQuantityNoPhone}}</router-link> -->
                    <router-link v-if="scope.row.GroupType == 1" :to="{path:'/CrowdList',query:{hasPhone: 1,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantityNoPhone}}</router-link>
                    <router-link v-if="scope.row.GroupType == 2" :to="{path:'/YingxunList',query:{hasPhone: 1,isCrowd:1,ID: scope.row.ID}}" style='color:#409EFF;'>{{scope.row.UnitQuantityNoPhone}}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop="CreateTime" label="创建日期" align="center"></el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getSocialGroupListPage,deleteSocialGroup } from '@/api/api'
import Qs from 'qs'
export default {
    components:{Toolbar},
    data() {
        return {
            buttonList: [],
            filters:{
                sel:null,
                name:'',
                selStatus: '',
                groupType: '',
            },
            tableData: [],
            options:[],
            options1:[
                {CertificateName:'所有证书',ID:null},
                {CertificateName:'待发证',ID:0},
                {CertificateName:'已发证',ID:1},
                {CertificateName:'已撤销',ID:2},
            ],
            groupTypeArr:[
                {name: '社会单位',value: 1},
                {name: '应训人员',value: 2},
            ],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getDataList(){
            this.listLoading = true
            var params = {
                name:this.filters.name?this.filters.name:null,
                certificateDesignID:this.filters.sel,
                certificateStatus:this.filters.selStatus?this.filters.selStatus:null,
                groupType:this.filters.groupType,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            getSocialGroupListPage(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.tableData = result.Response.Data
                }else{
                    this.listLoading = false
                }
            })
        },
        getSocialGroupListPage() {
            this.getDataList();
        },
        // 删除
        handleDel() {
            if(this.needData) {
                this.$confirm(`确定删除已选中人群？`,"提示",{
                }).then(() => {
                    var params = {
                        ID: this.needData.ID
                    }
                    deleteSocialGroup(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.warning(result.Message)
                            this.getDataList();
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
            } else {
                this.$message.warning("请选择要删除数据！")
            }
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        // 筛选项input框清空
        handleClear(){
            
        },
        // 单元格样式
        rowClass () {
            return 'padding: 8px 0!important;text-align:center;'
        },
    },
    created(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
    }
}
</script>

<style lang="stylus" scoped>

</style>